.next-steps .fa-link {
  margin-right: 5px;
}

/* Fix for use only flexbox in content area */
.next-steps .row {
  margin-bottom: 0;
}

.next-steps .col-md-5 {
  margin-bottom: 3rem;
}

@media (max-width: 768px) {
  .next-steps .col-md-5 {
    margin-bottom: 0;
  }
}

.signup-form {
  text-align: center;
  margin-top: 50px;
}

.sign-up-submit {
  float: right;
  margin: 20px 0px 20px 0px;
}

.nav-container {
  min-height: 14%;
}

.toggle-button {
  border-radius: 20px;
  background-color: #ff5e24 !important;
  border-color: #ff5e24;
  color: #fff;
}

.signals-header {
  font-size: 1.25rem !important;
  margin-bottom: 20px !important;

}

.home-headlines {
  text-align: center;
}

.profile-heads {
  font-size: 1.0rem;
  font-weight: 400;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

.profile-row {
  margin-top: 25px;
}

.profile-devices {
  padding: 20px;
  border-width: 3px;
}

.profile {
  margin-top: 2%;
}

.css-1kbb6u6 {
  display: inline;
  color: rgb(255, 93, 34);
  font-weight: 700;
}

.profile-sub-heads {
  display: inline;
  color: rgb(255, 93, 34);
  font-weight: 400;
}

.footer1 {
  background-color: #eff1f5 !important;
}

.spinner {
  position: absolute;
  display: flex;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  background-color: white;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.result-block-container .result-block {
  opacity: 1;
}

.visitor-data {
  margin-bottom: 30px;
}

.btn-margin {
  margin: 10px;
  border-radius: 20px;
  min-width: 155px;
  min-height: 40px;
}

.btn-toggle {
  background-color: #ff5e24;
  border-color: #ff5e24;
  color: #fff;
  margin: 20px 0px 20px 0px;
  border-radius: 6px;
  min-width: 155px;
  min-height: 40px;
}


input[type=text],
select,
textarea {
  width: 100%;
  padding: 6px;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: vertical;
}

input[type=email] {
  width: 100%;
  padding: 6px;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: vertical;
}

input[type=password] {
  width: 100%;
  padding: 6px;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: vertical;
}

label {
  padding: 6px 6px 6px 0;
  display: inline-block;
}

input[type=submit] {
  background-color: #04AA6D;
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  float: right;
}

input[type=submit]:hover {
  background-color: #45a049;
}


.col-label {
  text-align: left;
}

form {
  text-align: center;
  width: 60%;
  margin: auto;
  display: table;
}

/* Clear floats after the columns */
.row::after {
  content: "";
  display: table;
  clear: both;
}



/* Responsive layout - when the screen is less than 600px wide, make the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: 600px) {

  .col-25,
  .col-75,
  input[type=submit] {
    width: 100%;
    margin-top: 20px;
  }
}